/** @module                     React-Router */

import { useNavigate }          from "react-router-dom";

/** @module                     React-Icons */

import { AiOutlineEdit }        from "react-icons/ai";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns table edit entry action
 * 
 */

Edit = ( { route } ) => { const navigate = useNavigate (),

    /** @returns navigate to correct edit entry page */

    editProduct = () => navigate ( route );

    /** @returns */

    return ( <button className="edit" onClick={ editProduct }>
        
        <AiOutlineEdit />
        
    </button> );

};

/** @exports Edit */
 
export default Edit;