/** @module                     Hooks */

import useFetch                 from "../../../../Hooks/useFetch";

import { useTranslation }       from "react-i18next";

import { useEffect, useRef }    from "react";

/** @module                     Queries */

import { TABLE_SORT_PARAMS }    from "../../../../Queries/Enums";

/** @module                     React-Icons */

import { 
    
    GoTriangleDown, 
    
    GoTriangleUp }              from "react-icons/go";

import { MdTireRepair }         from "react-icons/md";

import { IoIosConstruct }       from "react-icons/io";

/** @module                     Components */

import { Fragment }             from "react";

import Pagination               from "./Pagination";

import ComponentLoader          from "../../Loaders/ComponentLoader";

import Bool                     from "./Bool";

import Text                     from "./Text";

import Routing                  from "./Link";

import Tag                      from "./Tag";

import Edit                     from "./Actions/Edit";

import Preview                  from "./Actions/Preview";

import Information              from "./Actions/Entry";

import ObjectCount              from "./ObjectCount";

import Image                    from "./Image";

import OrderAssembly            from "../../Orders/Table/assembly";

import Fieldset                 from "../Forms/Fieldset";

/** @module                     Utilities */

import { convertDatetime }      from "../../Utils/Datetime";

import { camelToSnake }         from "../../Utils/caseConverter";

const 

/**
 * 
 * @returns table component with data and pagination
 * 
 */

FetchTable = ( {

    query,

    query_atts = {},

    identifier,

    header,

    contents, 

    actions = [],

    sort = false,

    stringSearch = false,

    filter = false,

    filters = [],

    filtersElement,

    sort_values = [],

    before_table = null

} ) => { const { t } = useTranslation (),

    /**
     * 
     * @event useFetch
     * 
     * @returns query data, loading state and refetch callback
     * 
     * @description fetches table sort params
     * 
     */

    { loading : loadSort, data: sortParams } = useFetch ( TABLE_SORT_PARAMS ),

    sort_state = useRef ( null ),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    { loading, data, refetch, variables } = useFetch ( query, { 

        ...query_atts,
            
        first: JSON.parse ( localStorage.getItem ( `${ identifier }CollectionCount` ) ) || 12

    }, "cache-and-network" ),

    /**
     * 
     * @event handleSort
     * 
     * @returns refetch callback
     * 
     * @description sorts table data
     * 
     * @param { object } event
     * 
     */

    handleSort = event => {

        /** @returns if sort parameters are still loading */

        if ( loadSort ) return;

        /** @returns if sort parameters are not loaded */

        if ( typeof sortParams === "undefined" ) return;

        /** @returns if sort parameters are not loaded */

        if ( typeof sortParams.__type === "undefined" ) return;

        /** @returns if sort parameters are not loaded */

        if ( typeof sortParams.__type.enumValues === "undefined" ) return;

        const 

        /** @returns sort parameter */

        sortParam = camelToSnake ( event.target.dataset.name ),

        /** @returns sort parameter index */

        sortParamIndex = sortParams.__type.enumValues.findIndex ( sort_value => sort_value.name.toLowerCase () === sort_state.current ),

        /** @returns next sort parameter */

        nextSortParam = sortParamIndex === sortParams.__type.enumValues.length - 1 ? sortParams.__type.enumValues[ 0 ].name.toLowerCase () : sortParams.__type.enumValues[ sortParamIndex + 1 ].name.toLowerCase ();

        /** @returns sort state */

        sort_state.current = nextSortParam;

        /** @returns refetch callback */

        refetch ( { sortParams: { column: sortParam, direction: nextSortParam } } );

    },

    EntryFilter = filtersElement || Fragment;

    /** @event useEffect */

    useEffect ( () => {

        if ( ! loadSort ) { const 

            sortValue = sortParams.__type.enumValues[ 0 ].name.toLowerCase ();

            sort_state.current = sortValue;

        }

    }, [ loadSort ] );

    /** @returns */

    return ( 
    
        <Fragment>

            { before_table && <before_table.component loading={ loading } data={ data } refetch={ refetch } variables={ variables } /> }

            { filter && 
            
                <Fieldset legend={ t ( "filters" ) }>

                    {

                        <EntryFilter 

                            refetch={ refetch }
                        
                            variables={ variables }
        
                            loading={ loading }
                            
                        />

                    }

                </Fieldset>
            
            }

            { loading ? <ComponentLoader /> :
            
                <Fragment>

                    { ( typeof data != "undefined" ) &&
                
                        <Pagination 
                            
                            pageinfo={ data[ Object.keys ( data )[ 0 ] ].pageInfo } 
                            
                            action={ refetch } 
                            
                            cookie={ `${ identifier }CollectionCount` }
                            
                            stringSearch={ stringSearch } /> 

                    }

                    <table>

                        <thead>

                            <tr>

                                { 
                                
                                    header.map ( ( value, index ) =>  
                                    
                                        sort === false ? 
                                        
                                            <th key={ index }>
                                                
                                                { value === "assembly" ? <IoIosConstruct /> : t ( value ) }
                                                
                                            </th> 
                                            
                                        : 

                                            sort_values.find ( sort_value => sort_value === value ) ?

                                                <th key={ index } className="sortable" onClick={ handleSort } data-name={ value }>

                                                    <div className="sortableCell" data-name={ value }>

                                                        { value === "assembly" ? <IoIosConstruct /> : t ( value ) }

                                                        <span className="icon">

                                                            { sort_state.current === "asc" ? <GoTriangleUp /> : <GoTriangleDown /> }

                                                        </span>

                                                    </div>

                                                </th>

                                            :

                                                <th key={ index }>
                                                    
                                                    { value === "assembly" ? <IoIosConstruct /> : t ( value ) }
                                                    
                                                </th>

                                    ) 
                                
                                }

                                {

                                    actions.length > 0 && <th>

                                        { t ( "actions" ) }

                                    </th>

                                }

                            </tr>

                        </thead>

                        <tbody>

                            { ( typeof data != "undefined" ) &&

                                data[ Object.keys ( data )[ 0 ] ].edges.filter ( edge => edge.node !== null ).map ( ( row, index ) =>

                                    <tr key={ `table_row_${ index }` }>

                                        <Fragment>

                                            { 
                                            
                                                contents.map ( ( content, key ) => 

                                                    <Fragment key={ `table_data_${ key }` }>

                                                        { /** @returns link with a specific target key name and dynamic data key route */
                                                        
                                                            content.type === "target_key_link_name" && 

                                                                <Routing 
                                                            
                                                                    text={ row.node[ content.text_param ] } route={ 
                                                                        
                                                                            content.route.replace ( "%PARAM%", row.node[ content.link_param ] ) 
                                                                        
                                                                        } /> }

                                                        { /** @returns link with a specific target key name and dynamic data key route */
                                                        
                                                            content.type === "target_key_link" && 

                                                                <Routing 
                                                            
                                                                    text={ row.node[ content.param ] } route={ 
                                                                        
                                                                            content.route.replace ( "%PARAM%", row.node[ content.param ] ) 
                                                                        
                                                                        } /> }

                                                        { /** @returns link with a specific data key name and custom route */
                                                        
                                                            content.type === "key_link" && 

                                                                <Routing 
                                                            
                                                                    text={ row.node[ content.key ] } route={ content.route } /> }

                                                        { /** @returns link with custom text and custom route */
                                                        
                                                            content.type === "link" && 

                                                                <Routing 
                                                            
                                                                    text={ content.text } route={ content.route } /> }

                                                        { /** @returns link with custom text and route that has dynamic data key */
                                                        
                                                            content.type === "target_link" && 

                                                                <Routing 
                                                            
                                                                    text={ content.text } route={ content.route.replace ( "%PARAM%", row.node[ content.param ] ) } /> }

                                                        { /** @returns true or false icon from a specific data key */
                                                        
                                                            content.type === "boolean" && 

                                                                <Bool 
                                                                
                                                                    state={ row.node[ content.key ] } /> }

                                                        { /** @returns true or false icon from a specific data key */
                                                        
                                                            content.type === "image" && 

                                                                <Image 
                                                                
                                                                    src={ row.node[ content.src ] }
                                                                    
                                                                    alt={ row.node[ content.alt ] } /> }

                                                        { /** @returns datetime string from a specific data key */
                                                        
                                                            content.type === "date" && 

                                                                <Text 
                                                                
                                                                    text={ convertDatetime ( row.node[ content.key ] ) } /> }

                                                        { /** @returns string from a specific data key */
                                                        
                                                            content.type === "key_text" && 

                                                                <Text 
                                                                
                                                                    text={ row.node[ content.key ] } /> }

                                                        { /** @returns tag with specific data key as content */
                                                        
                                                            content.type === "keytag" && 

                                                                <Tag 
                                                                
                                                                    text={ row.node[ content.key ] } /> }

                                                        { /** @returns tag with specific data key as content */
                                                        
                                                            content.type === "status" && 

                                                                <Tag 
                                                                
                                                                    text={ t ( row.node[ content.key ] ) } 
                                                                    
                                                                    style={ row.node[ content.key ] } /> }

                                                        { /** @returns tag with custom string */
                                                        
                                                            content.type === "tag" && 

                                                                <Tag 
                                                                
                                                                    text={ content.text } /> }

                                                        { /** @returns query object count */
                                                        
                                                            content.type === "object_count" && 

                                                            <ObjectCount 
                                                            
                                                                data={ row.node[ content.key ] } 
                                                            
                                                                link={ content.link } 
                                                            
                                                                tab={ content.tab }
                                                                
                                                                route={ content.route.replace ( "%PARAM%", row.node.id ) } /> }

                                                        { /** @returns order assembly */

                                                            content.type === "preset" &&

                                                                content.preset === "assembly" &&

                                                                    <OrderAssembly data={ row.node } /> }

                                                        { /** @returns custom component */
                                                        
                                                            content.type === "component" &&

                                                                <content.component

                                                                    data={ row.node } />
                                                        
                                                        }

                                                    </Fragment>
                                                
                                                ) 
                                            
                                            }

                                            { actions.length > 0 &&

                                                <td data-title="actions" className="actions">

                                                    { 
                                                    
                                                        actions.map ( ( action, index ) =>  
                                                        
                                                            <Fragment key={ index }>

                                                                {

                                                                    action.type === "custom" &&

                                                                    <action.component

                                                                        data={ row.node } />

                                                                }

                                                                { action.type === "edit" && 
                                                                
                                                                    <Edit route={ `${ action.permalink }/edit/${ row.node.id }` } />
                                                                
                                                                }

                                                                { action.type === "preview" && 
                                                                
                                                                    <Preview 
                                        
                                                                        element={ 
                                                                        
                                                                            <Information atts={ { id : row.node.id, query : action.query } } /> 
                                                                        
                                                                        } 
                                                                            
                                                                    />
                                                                
                                                                }

                                                            </Fragment>

                                                        ) 
                                                        
                                                    }

                                                </td>

                                            }

                                        </Fragment>

                                    </tr>

                                )

                            }
                            
                        </tbody>

                    </table>

                    { ( typeof data != "undefined" ) &&
                
                        <Pagination 
                            
                            pageinfo={ data[ Object.keys ( data )[ 0 ] ].pageInfo } 
                            
                            action={ refetch } 
                            
                            cookie={ `${ identifier }CollectionCount` }
                            
                            stringSearch={ false } /> 
                    
                    }
                    
                </Fragment> 
            
            }

        </Fragment> 
    
    );

}

/** @exports FetchTable */
 
export default FetchTable;