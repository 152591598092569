/** @module                         React */

import { useState }                 from "react";

import { useTranslation }           from "react-i18next";

/** @module                         Components */

import SelectSpread                 from "../../../../Global/Forms/Select_Spread";

const 

/**
 * 
 * @param { Object } attributes
 * 
 * @param { Object } variables
 * 
 * @param { Function } refetch
 * 
 * @returns diameter filter component
 * 
 */

Diameter = ( { name, attributes, variables, refetch, label = true } ) => { const 

    { t } = useTranslation (),

    /** @state */

    [ value, setValue ] = useState ( 
        
        variables[ name ] === "undefined" ? "default" : variables[ name ] 
        
    ),

    /**
     * 
     * @param { Object } e
     * 
     * @returns filter callback
     * 
     * @event handleChange
     * 
     */

    handleChange = e => {
        
        /** @event setValue */

        setValue ( e.target.value );

        /** @event refetch */

        refetch ( 
            
            { 
                
                [ e.target.name ] : e.target.value === "default" ? undefined : parseFloat ( e.target.value ),

                first: ( typeof variables.first === "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            } 
            
        );

    };

    /** @returns */

    return (

        <SelectSpread 
        
            id={ name }

            label={ ( label === true ) ? t ( name ) : false }

            options={ parseOptions ( attributes ) }

            value={ value }

            def={ true }

            defValue={ t ( name ) }

            action={ handleChange }
        
        />

    );

},

/**
 * 
 * @param { Array } atts
 * 
 * @returns parsed options
 * 
 */

parseOptions = ( atts ) => { let options = [];

    atts.map ( att => {

        options.push ( {

            name    : att.text,

            value   : parseFloat ( att.value )

        } );

    } );

    /** @returns */

    return options.sort ( ( a, b ) => a.value - b.value );

}

/** @exports Diameter */
 
export default Diameter;