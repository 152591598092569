import ProductFilters from "../Shared/Filters";

const 

OrderProductFilters = ( { updateData } ) => {

    return (

        <div className="body__pane accented rounded_right">

            { updateData &&

                <ProductFilters 
                    
                    productType={ updateData.productType }
                            
                    refetch={ updateData.refetch }
                    
                    variables={ updateData.variables }
                
                />

            }

        </div>

    );

}

/** @exports OrderProductFilters */
 
export default OrderProductFilters;