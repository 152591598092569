import styles from "./Filters.module.css";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import { ORDER_TYPE_ENUM, ORDER_STATUS_ENUM, ORDER_DELIVERY_METHOD_ENUM, ORDER_PAYMENT_METHOD_ENUM } from "./Queries";

import { FilterSelectEnum } from "./Elements/FilterSelect";

import FiltersReset from "../../Products/Collection/Filters/Elements/Reset";

import CenterLoader from "../../Loaders/CenterLoader";

import FilterText from "./Elements/FilterText";

import FilterManagerOrders from "./Elements/ManagerOrder";

export default function OrderFilters ( { refetch, variables, loading } ) { const 

    { t } = useTranslation (),

    componentData = {

        // FilterMultipleEnumTypes: {

        //     component: FilterSelectEnum,

        //     props: { refetch : refetch, variables : variables, name : "orderType", query : ORDER_TYPE_ENUM, label : "orderType", loader : loading },

        //     dataName: false,

        //     gridOptions: { gridColumn: "1 / 3" }

        // },

        FilterMultipleEnumStatuses: {

            component: FilterSelectEnum,

            props: { refetch : refetch, variables : variables, name : "status", query : ORDER_STATUS_ENUM, label : "status", loader : loading },

            dataName: false,

            gridOptions: { gridColumn: "1 / 3" }

        },

        FilterMultipleEnumDeliveryMethods: {

            component: FilterSelectEnum,

            props: { refetch : refetch, variables : variables, name : "deliveryMethod", query : ORDER_DELIVERY_METHOD_ENUM, label : "deliveryMethod", loader : loading },

            dataName: false,

            gridOptions: { gridColumn: "3 / 5" }

        },

        FilterMultipleEnumPaymentMethods: {

            component: FilterSelectEnum,

            props: { refetch : refetch, variables : variables, name : "paymentMethod", query : ORDER_PAYMENT_METHOD_ENUM, label : "paymentMethod", loader : loading },

            dataName: false,

            gridOptions: { gridColumn: "5 / 6" }

        },

        FilterManagerOrders: {

            component: FilterManagerOrders,

            props: { refetch : refetch, variables : variables, name : "managerOrder" },

            dataName: false,

            gridOptions: { gridColumn: "6 / 8" }

        },

        FilterTextStartDate: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "dateRangeStart", parse : "date", placeholder : t ( "dateRangeStart" ), loader : loading },

            dataName: false,

            gridOptions: { gridColumn: "1 / 3" }

        },

        FilterTextEndDate: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "dateRangeEnd", parse : "date", placeholder : t ( "dateRangeEnd" ), loader : loading },

            dataName: false,

            gridOptions: { gridColumn: "3 / 5" }

        }

    };

    return (

        // loading ? <CenterLoader /> :

        <div className={ styles.container }>

            <div className={ styles.content }>

                {
                
                    Object.keys ( componentData ).map
                    
                    ( ( componentKey, index ) => {

                            const { component: Component, props, gridOptions } = componentData[componentKey];

                            return (

                                <div style={ gridOptions } key={ index }>

                                    <Component { ...props } />

                                </div>

                            );

                        }
                    
                    )
                    
                }

            </div>

            <FiltersReset variables={ variables } refetch={ refetch } />

        </div>

    );

};