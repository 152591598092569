/** @module                         Hooks */

import { useEffect, useState }      from "react";

import { useTranslation }           from "react-i18next";

/** @module                         React-Icons */

import { AiOutlineCloseCircle }     from "react-icons/ai";

/** @module                         Components */

import { Fragment }                 from "react";

import Button                       from "../../../../Global/Forms/Button";

import OrderProductTable            from "./Table";

import OrderProductFilters          from "./Filters";

import StaticFilters                from "../../StaticFilters";

import OrderProductPreview          from "./Preview";

const 

/**
 * 
 * @param { Object } orderData
 * 
 * @returns modal
 * 
 * @description modal for adding products to order
 * 
 */

OrderProductModal = ( { orderData } ) => { const { t } = useTranslation (),

    [ modal, setModal ] = useState ( false ),

    [ preview, setPreview ] = useState ( false ),

    [ persistantPreview, setPersistantPreview ] = useState ( false ),

    [ updateData, setUpdateData ] = useState ( null ),

    [ products, setProducts ] = useState ( [] ),

    /**
     * 
     * @param { Object } e
     * 
     * @returns modal controller
     * 
     * @description toggles modal
     * 
     * @event onClick
     * 
     */

    modalController = e => { e.preventDefault ();

        setModal ( ! modal );

    },

    /**
     * 
     * @param { Object } e
     * 
     * @returns modal container controller
     * 
     * @description closes modal
     * 
     * @event onClick
     * 
     */

    modalContainerController = e => { 

        if ( 
            
            e.target.classList.contains ( "product_modal_container" ) 
            
        || 
            
            e.target.classList.contains ( "inner__container" ) ) 
            
                setModal ( ! modal );

    },

    /**
     * 
     * @returns adds selected products to order
     * 
     * @description adds selected products to order
     * 
     * @event onClick
     * 
     */

    handleClick = () => { const 

        currentList = orderData.view.orderProductVendors,

        newList = [ ...currentList, ...products ];

        orderData.set ( { ...orderData.view, orderProductVendors: newList } );

        setModal ( ! modal );

    };

    /**
     * 
     * @returns modal
     * 
     * @description sets modal to false when modal is closed
     * 
     * @event useEffect
     * 
     */

    useEffect ( () => {

        if ( ! modal ) {

            setPreview ( false );

            setPersistantPreview ( false );

            setUpdateData ( null );

            setProducts ( [] );

        }

    }, [ modal ] );

    /** @returns */


    return ( 

        <Fragment>

            { modal && 
            
                <div className="product_modal_container" onClick={ e => modalContainerController ( e ) }>

                    <div className="inner__container">

                        <div className="inner__content">

                            <div className="inner__header">

                                <span 
                                
                                    onClick={ e => modalController ( e ) } 
                                    
                                    className="close">

                                    <AiOutlineCloseCircle size={ 20 } />

                                </span>

                            </div>

                            <div className="inner__body">

                                <OrderProductPreview preview={ preview } />

                                <OrderProductTable 
                                
                                    setPreview={ setPreview } 

                                    preview={ preview }

                                    persistantPreview={ persistantPreview }

                                    setPersistantPreview={ setPersistantPreview }

                                    updateData={ setUpdateData }

                                    updateDataVars={ updateData }

                                    products={ products }

                                    setProducts={ setProducts }

                                    orderData={ orderData }

                                    __={ t }
                                    
                                />

                                {/* <OrderProductFilters updateData={ updateData } /> */}

                            </div>

                            <div className="inner__footer">

                                <Button
                                
                                    text="close"

                                    action={ e => modalController ( e ) }
                                
                                />

                                <Button

                                    action={ handleClick }
                                
                                    text="add_selected_products"
                                
                                />

                            </div>

                        </div>

                    </div>

                </div>
                
            }
        
            <button onClick={ e => modalController ( e ) }>

                { t ( "add_product" ) }

            </button>

        </Fragment>
    
    );

}

/** @exports OrderProductModal */
 
export default OrderProductModal;