import styles from "./OrderCreatedAt.module.css";

import { MANAGER } from "../../../../../Queries/Managers";

import { useQuery } from "@apollo/client";

import CenterLoader from "../../../Loaders/CenterLoader";

import { convertDatetime } from "../../../Utils/Datetime";

export default function OrderCreatedAt ( { data } ) {

    const 

    { data : user, loading } = useQuery ( MANAGER, { variables: { id: data.userId } } );

    return (

        <td data-title="createdAt" className={ styles.container }>

            {

                data?.invoiceNumber &&

                <div className={ styles.invoiceNumber }>

                    { data?.invoiceNumber }

                </div>

            }

            <div className={ styles.date }>

                { convertDatetime ( data?.createdAt ) }

            </div>

            <div className={ styles.manager }>

                { loading ? <CenterLoader /> : `${ user.adminUser.firstName } ${ user.adminUser.lastName }` }

            </div>

        </td>

    );

};