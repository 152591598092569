/** @module                             Hooks */

import useFetch                         from "../../../../Hooks/useFetch";

import { useEffect }                    from "react";

import { useTranslation }               from "react-i18next";

/** @module                             React-Icons */

import { AiOutlineCloseCircle }         from "react-icons/ai";

import { ImSpinner5 }                   from "react-icons/im";

/** @module                             Queries */

import { ORDER_PRICE_CALCULATOR }       from "../../../../Queries/Orders";

/** @module                             Components */

import Image                            from "../../Global/Table/Image";

import Text                             from "../../Global/Table/Text";

import Link                             from "../../Global/Table/Link";

import { Fragment }                     from "react";

const 

/**
 * 
 * @param { Object } order 
 * 
 * @returns product table of current order
 * 
 */

OrderProducts = ( { order, setState, isEditing = false } ) => { const 
    
    { t : __ } = useTranslation (),
    
    removeProduct = ( e, id ) => { e.preventDefault ();

        const orderProductVendors = order.orderProductVendors.filter ( product => product.productId != id );

        setState ( { ...order, orderProductVendors } );

    },

    updateQuantity = ( e, id) => {

        if ( e.target.value === "" ) return;

        if ( parseInt ( e.target.value ) < 0 ) return;

        const orderProductVendors = order.orderProductVendors.map((product) => {

            if (product.productId === id) {

                const totalQty = sumQuantity ( product.product.productVendors );

                const updatedProduct = { ...product, count: ( parseInt ( e.target.value ) > totalQty ) ? totalQty : parseInt ( e.target.value ) };
                
                return updatedProduct;
            }

            return product;

          });
          
          setState({ ...order, orderProductVendors });

    },


    updateProductPrice = ( e, id) => {

        if ( e.target.value === "" ) return;

        let price = parseFloat ( e.target.value );

        if ( price < 1 ) price = 1;

        const orderProductVendors = order.orderProductVendors.map((product) => {

            if (product.productId === id) {

                const updatedProduct = { ...product, price: price, };
                
                return updatedProduct;
            }

            return product;

          });
          
          setState({ ...order, orderProductVendors });

    },

    updateDiscount = ( e, id) => {

        if ( e.target.value === "" ) return;

        let discount = parseFloat ( e.target.value );

        if ( discount < 0 ) discount = 0;

        if ( discount > 100 ) discount = 100;

        const orderProductVendors = order.orderProductVendors.map((product) => {

            if (product.productId === id) {

                const updatedProduct = { ...product, discount: discount};
                
                return updatedProduct;
            }

            return product;

          });
          
          setState({ ...order, orderProductVendors });

    },

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading, data } = useFetch ( ORDER_PRICE_CALCULATOR, { 

        countryCode: order.countryCode,
    
        city: order.city,

        deliveryPrice: order.deliveryMethod === "сourier" ? order.customDeliveryPrice : 0,

        servicePrice: order.assembly ? order.assemblyPrice : 0,

        deliveryPlanId: order.deliveryPlanId || undefined,

        vat: order.vatIncluded,

        productParams: priceCalculatorProducts ( order.orderProductVendors )

    } );

    useEffect ( () => {

        if ( typeof data != "undefined" ) {

            setState ( { ...order, deliveryPrice: parseFloat ( data.orderPriceCalculator.delivery.toFixed ( 2 ) ) } );

        }

    }, [ data ] );

    /** @returns */

    return ( 
    
        <table>

            <thead>

                <tr>

                    <th>
                                
                        &#160;
                        
                    </th>

                    <th>
                                
                        { __( "image" ) }
                        
                    </th>


                    <th>
                                
                        { __( "id" ) }
                        
                    </th>

                    <th>
                                
                        { __( "name" ) }
                        
                    </th>

                    <th>
                                
                        { __( "productType" ) }
                        
                    </th>

                    <th>
                                
                        { __( "vendorName" ) }
                                                
                    </th>

                    <th>
                                
                        { __( "vendorPrice" ) }
                                
                    </th>

                    <th>
                                
                        { __( "price" ) }
                        
                    </th>

                    <th>
                                
                        { __( "discount" ) + " %" }
                                        
                    </th>

                    <th>
                                
                        { __( "quantity" ) }
                                
                    </th>

                    <th>
                                
                        { __( "totals" ) }
                        
                    </th>

                </tr>

            </thead>

            <tbody>

                { ( typeof order != "undefined" ) && 

                    order.orderProductVendors.map ( ( product, index ) => {

                        const

                        productTypes = {

                            tire: `${ product.product.width } | ${ product.product.height } | R${ parseInt ( product.product.diameter ) }${ product.product?.isCTire ? "C" : "" } | ${ product.product?.loadIndex }${ product.product?.speedRating?.toUpperCase () }`,

                            rim: `${ product?.product?.width || "-" }J | R${ parseInt ( product?.product?.diameter ) || "-" } | ${ product?.product?.pitchCircleDiameter || "-" } | ET${ product?.product?.offset || "-" } | CB${ product?.product?.centreBore || "-" }`,

                            battery: `${ product.product.voltage } V | ${ product.product.current } A | ${ product.product.capacity } Ah`,

                        };

                        return (

                            <tr key={ index }>

                                <td className="centered">

                                    <span onClick={ e => removeProduct ( e, product.productId ) } className="standalone_remove_action">

                                        <AiOutlineCloseCircle size={ 20 } />

                                    </span>

                                </td>

                                <Image src={ product.product.currentImage } alt={ product.id } />

                                <Text text={ __ ( product.productId ) } />

                                <Link 
                                
                                    text={ `${ product.product.manufacturer.name.toUpperCase () } ${ product.product.model } - ${ productTypes[ product.product.productType ] }` }

                                    route={ `/app/products/edit/${ product.product.id }` }
                            
                                />

                                <Text text={ __ ( product.product.productType ) } />

                                <Text text={ __ ( product.productVendorId ? product.vendorName : product.product.vendorNames.join(", ") ) } />
                                
                                <Text text={ `\u20AC ${ parseFloat ( product.originalPrice || product.product.priceList.retail_price ).toFixed ( 2 ) }` } />

                                {isEditing ?
                                    <>

                                        <td data-title="text" className="input_cell">

                                            <input type="number" style={ { width: 100 } } value={ product.price } onChange={ e => updateProductPrice ( e, product.productId ) } />

                                        </td>

                                        <td data-title="text" className="input_cell">

                                            <input type="number" style={ { width: 100 } } value={ product.discount } onChange={ e => updateDiscount ( e, product.productId ) } />

                                        </td>
                                    </>
                                :
                                    <>
                                
                                        <Text text={ `\u20AC ${ parseFloat ( product.price ).toFixed ( 2 ) }` } />

                                        <Text text="0" />
                                    </>
                                }

                                <td data-title="text" className="input_cell">

                                    <input type="number" style={ { width: 100 } } value={ product.count } onChange={ e => updateQuantity ( e, product.productId ) } />

                                </td>

                                <Text text={ `\u20AC ${ parseFloat ( product.price * product.count ).toFixed ( 2 ) }` } />

                            </tr>

                        )

                    } )
                
                }

                { order.assembly && 

                    <tr>

                        <td colSpan="9">&nbsp;</td>

                        <td>{ __ ( "assembly" ) }:</td>

                        <td>

                            { loading ? <ImSpinner5 className="spinner" size={ 16 } /> : 

                                <Fragment>
                            
                                    &euro;

                                    &nbsp;

                                    { data.orderPriceCalculator.service.toFixed ( 2 ) }

                                </Fragment>
                            
                            }

                        </td>

                    </tr>

                }

                <tr>

                    <td colSpan="9">&nbsp;</td>

                    <td>{ __ ( "delivery" ) }:</td>

                    <td>

                        { loading ? <ImSpinner5 className="spinner" size={ 16 } /> : 

                            <Fragment>
                        
                                &euro;

                                &nbsp;

                                { data.orderPriceCalculator.delivery.toFixed ( 2 ) }

                            </Fragment>
                        
                        }

                    </td>

                </tr>

                <tr>

                    <td colSpan="9">&nbsp;</td>

                    <td>{ __ ( "discount" ) }:</td>

                    <td>

                        { loading ? <ImSpinner5 className="spinner" size={ 16 } /> : 

                            <Fragment>
                        
                                &euro;

                                &nbsp;

                                { "- " + data.orderPriceCalculator.total_discount.toFixed ( 2 ) }

                            </Fragment>
                        
                        }

                    </td>

                </tr>

                <tr>

                    <td colSpan="9">&nbsp;</td>

                    <td>{ __ ( "total_execl_vat" ) }:</td>

                    <td>

                        { loading ? <ImSpinner5 className="spinner" size={ 16 } /> : 

                            <Fragment>
                        
                                &euro;

                                &nbsp;

                                { data.orderPriceCalculator.total_execl_vat.toFixed ( 2 ) }

                            </Fragment>
                        
                        }

                    </td>

                </tr>

                <tr>

                    <td colSpan="9">&nbsp;</td>

                    <td>{ __ ( "vat" ) }:</td>

                    <td>

                        { loading ? <ImSpinner5 className="spinner" size={ 16 } /> : 

                            <Fragment>
                        
                                &euro;

                                &nbsp;

                                { data.orderPriceCalculator.vat.toFixed ( 2 ) }

                            </Fragment>
                        
                        }

                    </td>

                </tr>

                <tr>

                    <td colSpan="9">&nbsp;</td>

                    <td>{ __ ( "order_total" ) }:</td>

                    <td>

                        
                        {

                            loading ? <ImSpinner5 className="spinner" size={ 16 } /> : 

                            <Fragment>

                                &euro;

                                &nbsp;
                            
                                { data.orderPriceCalculator.total.toFixed ( 2 ) }

                            </Fragment>

                        }


                    </td>

                </tr>

            </tbody>

        </table> 
        
    );

},

priceCalculatorProducts = ( productsList ) => {

    return productsList.map ( product => {

        return { id: product.productId, qty: product.count, price: parseFloat ( product.price ), discount: product.discount };

    } );

},

/**
 * 
 * @param { Array } productsList
 * 
 * @returns total quantity of products
 * 
 * @description sums up all quantities of products in order
 * 
 */

sumQuantity = ( productsList ) => {

    return productsList.reduce ( ( acc, cur ) => acc + cur.qty + cur.qtyDays + cur.qtyHour + cur.qtyWeeks, 0 );

}

/** @exports OrderProducts */
 
export default OrderProducts;