// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderCreatedAt_container__MFeDA > *:not(:last-child) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.OrderCreatedAt_date__7oDOx {\n\n    color: var(--invert-solid);\n\n    text-wrap: nowrap;\n\n}\n\n.OrderCreatedAt_manager__dRoki {\n\n    font-size: 0.75rem;\n\n    text-wrap: nowrap;\n\n}\n\n.OrderCreatedAt_invoiceNumber__DAVps {\n\n    font-size: 0.75rem;\n\n    text-wrap: nowrap;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Global/Table/Cells/OrderCreatedAt.module.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;;AAE1B;;AAEA;;IAEI,0BAA0B;;IAE1B,iBAAiB;;AAErB;;AAEA;;IAEI,kBAAkB;;IAElB,iBAAiB;;AAErB;;AAEA;;IAEI,kBAAkB;;IAElB,iBAAiB;;AAErB","sourcesContent":[".container > *:not(:last-child) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.date {\n\n    color: var(--invert-solid);\n\n    text-wrap: nowrap;\n\n}\n\n.manager {\n\n    font-size: 0.75rem;\n\n    text-wrap: nowrap;\n\n}\n\n.invoiceNumber {\n\n    font-size: 0.75rem;\n\n    text-wrap: nowrap;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OrderCreatedAt_container__MFeDA",
	"date": "OrderCreatedAt_date__7oDOx",
	"manager": "OrderCreatedAt_manager__dRoki",
	"invoiceNumber": "OrderCreatedAt_invoiceNumber__DAVps"
};
export default ___CSS_LOADER_EXPORT___;
