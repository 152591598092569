import styles from "./FilterCheckbox.module.css";

import { useState, useEffect, useRef } from "react";

import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import { VscTriangleLeft, VscTriangleDown } from "react-icons/vsc";

import { BsCheck } from "react-icons/bs";

/**
 * 
 * @exports FilterCheckboxEnum
 * 
 * @description This component renders a checkbox input for filtering the product collection
 * 
 * @param { Object } props
 * 
 * @param { Array } props.data The data to be used to render the checkbox options
 * 
 * @param { Function } props.refetch The refetch function to refetch the product collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the product collection
 * 
 * @param { String } props.name The name of the variable to be used to refetch the product collection
 * 
 * @param { Boolean } props.loader The loader to be used to display the loading state
 * 
 * @param { Boolean } props.textValue The value to be used as the text value for the checkbox options
 * 
 * @param { String } props.parse The type of data to parse the value to before setting it to the state
 * 
 * @returns { JSX.Element } FilterCheckbox
 * 
 */

export default function FilterCheckboxEnum ( { query, refetch, variables, name, loader = false, textValue = false, parse = false } ) {

    const 

    { t : __ } = useTranslation (),

    { data, loading } = useQuery ( query ),

    [ values, setValue ] = useState ( variables [ name ] || [] ),

    [ input, setInput ] = useState ( "" ),

    [ open, setOpen ] = useState ( false ),

    toggleDropdown = () => setOpen ( ! open ),

    detector = useRef ( null ),

    filterInput = useRef ( null ),

    /**
     * 
     * @type { Function } handleCheckbox - Handles checkbox events
     * 
     * @param { Object } event - Checkbox event
     * 
     * @returns sets the value to the state
     * 
     */

    handleCheckbox = ( event ) => {

        const

        { checked, value } = event.target;

        if ( checked ) {

            setValue ( [ ...values, parse === "number" ? parseInt ( value ) : parse === "float" ? parseFloat ( value ) : value ] );

        } else {

            setValue ( values.filter ( item => item !== ( parse === "number" ? parseInt ( value ) : parse === "float" ? parseFloat ( value ) : value ) ) );

        }

    };

    /**
     * 
     * @event useEffect
     * 
     * @returns on keydown event, if the tab key is pressed and only one result is found, the value is set to the state and the dropdown is closed.
     * 
     */

    useEffect ( () => {

        const 

        handleKeydown = event => {

            if ( open && event.key === "Tab" ) {

                const

                results = data.__type.enumValues.filter ( item => item?.name.toString ().toLowerCase ().includes ( input.toLowerCase () ) );

                if ( results.length === 1 ) {

                    setValue ( [ ...values, parse === "number" ? parseInt ( results [ 0 ].name ) : parse === "float" ? parseFloat ( results [ 0 ].name ) : results [ 0 ].name ] );

                }

                setOpen ( false );

            }

        };

        document.addEventListener ( "keydown", handleKeydown );

        return () => { document.removeEventListener ( "keydown", handleKeydown ); };

    }, [ open, input ] );

    /**
     *  
     * @type { Function } handleOutsideClick - Handles outside click events 
     *
     * @param { Object } event - Outside click event
     *  
     */

    useEffect ( () => { const 
        
        handleOutsideClick = ( event ) => {

            if ( detector.current && ! detector.current.contains ( event.target ) ) {

                setOpen ( false );

            }

        };

        document.addEventListener ( "mousedown", handleOutsideClick );

        return () => document.removeEventListener ( "mousedown", handleOutsideClick );

    }, [] );

    /**
     * 
     * @event useEffect
     * 
     * @returns if the dropdown is closed, the input value is set to an empty string and the focus is removed from the input field.
     * 
     */

    useEffect ( () => {

        if ( ! open ) { setInput ( "" ); }

        if ( open ) { filterInput.current.focus (); }

    }, [ open ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns if the values array is empty, the variable is set to undefined, otherwise the variable is set to the values array.
     * 
     */

    useEffect ( () => {

        if ( values.length === 0 ) {

            refetch ( { ...variables, [ name ]: undefined } );

        } else {

            refetch ( { ...variables, [ name ]: values } );

        }

    }, [ values ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set the value of the input to the value of the variable in the variables object or an empty string if it is not found.
     * 
     */

    useEffect ( () => {

        setValue ( variables [ name ] || [] );

    }, [ variables ] );

    if ( loading ) return <div>Loading...</div>;

    /**
     * 
     * @description Render the component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <div className={ styles.container }>

            <div className={ styles.select }>

                <input 
                
                    type="text" 
                
                    placeholder={ __( name ) } 
                
                    className={ styles.trigger } 
                
                    value={ values?.length ? values.join ( ", " ) : "" } 
                
                    readOnly 
                
                    onFocus={ toggleDropdown } 

                    onClick={ e => { if ( ! open ) { setOpen ( true ) } } }
                    
                />

                <span className={ styles.chevron }>

                    { open ? <VscTriangleDown /> : <VscTriangleLeft /> }

                </span>

            </div>

            { /** If the dropdown is open, display the dropdown. */

                open &&
                
                <div className={ styles.dropdown } ref={ detector }>

                    <div className={ styles.search }>
                        
                        <input 
                        
                            type="text" 
                        
                            placeholder={ `${ __ ( "filter" ) }...` } 
                        
                            value={ input } 
                        
                            onChange={ ( event ) => setInput ( event.target.value ) } 
                        
                            ref={ filterInput } 
                            
                        />

                        { /** If the values array is not empty, display the clear button. */

                            values.length > 0 &&

                                <div className={ styles.clear } onClick={ e => { setValue ( [] ) } }>

                                    { __ ( "clear_all" ) }

                                </div>

                        }
                        
                    </div>

                    <div className={ styles.options }>

                        { /** Map the data to render the checkbox options. */

                            data.__type.enumValues.filter ( item => item?.name.toString ().toLowerCase ().includes ( input.toLowerCase () ) ).map ( ( item, index ) =>

                                <label htmlFor={ item?.name } key={ index } className={ styles.checkbox }>

                                        <input 
                                        
                                            type="checkbox" 
                                        
                                            id={ item?.name } 
                                        
                                            name={ item?.name } 
                                        
                                            value={ item?.name } 

                                            checked={ values.includes ( 

                                                parse === "number" ? parseInt ( item?.name ) : parse === "float" ? parseFloat ( item?.name ) : item?.name
                                            
                                            ) }
                                        
                                            onClick={ handleCheckbox } 

                                            tabIndex={ -1 }

                                            disabled={ loader }

                                            readOnly
                                            
                                        />

                                        <span className={ styles.checkboxIcon }>

                                            <span className={ styles.selected }>

                                                <BsCheck />

                                            </span>

                                        </span>

                                        { /** Capitalize the first letter of the text value if it is a string and not a number or float value. */
                                            
                                                parse === "number" ? parseInt ( item?.name ) : parse === "float" ? parseFloat ( item?.name ) 
                                            
                                            : 
                                            
                                                item?.name?.toString ().charAt ( 0 ).toUpperCase () + item?.name?.toString ().slice ( 1 )

                                        }

                                    </label>

                            )

                        }
                        
                    </div>

                </div>

            }

        </div>

    );

};