import { AiOutlineShoppingCart } from 'react-icons/ai';

import useCart from '../../../../../Hooks/Cart/useCart';

/**
 * 
 * @exports AddToCartAction
 * 
 * @description AddToCartAction component for the table actions.
 * 
 * @param { Object } props - The properties of the component.
 * 
 * @param { string } props.productId - The product ID.
 * 
 * @param { function } props.addToCart - The addToCart function.
 * 
 */

export function AddToCartAction ( { product } ) { const 

    { cart, addToCart } = useCart ();

    /** @returns { JSX.Element } AddToCartAction */

    return (

        <button 
        
            className="cart" 
        
            onClick={ () => addToCart ( 
                
                { 

                    id: product.id,

                    productId: product.id,

                    count: 1,

                    price: product.prices[ 0 ].price,

                    maxQuantity: sumQuantity ( product.productVendors ),

                    product: {

                        model: product.model,

                        currentImage: product.currentImage,

                        id: product.id,

                        manufacturer: product.manufacturer,

                        model: product.model,

                        priceList: product.priceList,

                        productType: product.productType,

                        productVendors: product.productVendors,

                        vendorNames: product.vendors.map ( vendor => vendor.name ),

                        currentImage: product.currentImage,

                        capacity: product.capacity,

                        diameter: product.diameter,

                        height: product.height,

                        width: product.width,

                        voltage: product.voltage,

                        current: product.current,

                        centreBore: product.centreBore,

                        boltPattern: product.boltPattern,

                        offset: product.offset,

                        isCTire: product.isCTire,

                        loadIndex: product.loadIndex,

                        pitchCircleDiameter: product.pitchCircleDiameter,

                        speedRating: product.speedRating

                    }
                
                } 
                
            ) } 
            
            disabled={ cart.find ( item => item.id === product.id ) }
            
        >

            <AiOutlineShoppingCart />

        </button>

    );

};

/**
 * 
 * @param { Array } productsList
 * 
 * @returns total quantity of products
 * 
 * @description sums up all quantities of products in order
 * 
 */

export const sumQuantity = ( productsList ) => {

    return productsList.reduce ( ( acc, cur ) => acc + cur.qty + cur.qtyDays + cur.qtyHour + cur.qtyWeeks, 0 );

};