/** @module                         Hooks */

import useFetch                     from "../../../../../../Hooks/useFetch";

/** @module                         GQL */

import { PRODUCT_INPUT_FIELDS }     from "../../../../../../Queries/Products";

import { SEARCH_ATTRIBUTES }        from "../../../../../../Queries/Products";

/** @module                         Configurations */

import { FILTERS }                  from "../../../../../../Configs/CONSTANTS/Products";

/** @module                         Components */

import CenterLoader                 from "../../../../Loaders/CenterLoader";

import Season                       from "../Filters/season";

import Width                        from "../Filters/width";

import Height                       from "../Filters/height";

import Diameter                     from "../Filters/diameter";

import Radius                       from "../Filters/radius";

import Offset                       from "../Filters/offset";

import Manufacturer                 from "../Filters/manufacturer";

import Model                        from "../Filters/model";

import VehicleType                  from "../Filters/vehicleType";

import Specifications               from "../Filters/specifications";

import PitchCircleDiameter          from "../Filters/pitchCircleDiameter";

import SpeedRating                  from "../Filters/speedRating";

import NoiseIndex                   from "../Filters/noiseIndex";

import LoadIndex                    from "../Filters/loadIndex";

import FuelEfficiency               from "../Filters/fuelEfficiency";

import WetBreakingEfficiency        from "../Filters/wetBreakingEfficiency";

import Voltage                      from "../Filters/voltage";

import Current                      from "../Filters/current";

import Capacity                     from "../Filters/capacity";

import SortOrder                    from "../Filters/sortOrder";

const 

ProductFilters = ( { productType, variables, refetch } ) => { const 

    /** 
     * 
     * @param { Object } data
     * 
     * @param { Boolean } loading
     * 
     * @returns query data and loading state
     * 
     * @event useFetch
     * 
     */

    { data, loading } = useFetch ( PRODUCT_INPUT_FIELDS ),

    /**
     * 
     * @param { Object } attributes
     * 
     * @param { Boolean } loadAttributes
     * 
     * @returns query data and loading state
     * 
     * @event useFetch
     * 
     */

    { data : attributes, loading : loadAttributes } = useFetch ( SEARCH_ATTRIBUTES );

    /** @returns */

    return (

        loading || loadAttributes ?
        
            <div className="modal-settings-container">

                <CenterLoader />

            </div>
        
        :

        <div className="modal-settings-container">

            { 

                ( FILTERS[ productType ] === undefined ) ? "Filters not found" :
            
                data.__type.inputFields.map ( ( type, index ) => 

                    FILTERS[ productType ].includes ( type.name ) &&

                        <div key={ index } className={ type.name }>

                            { /** @returns season filter */
                            
                                type.name === "season" && 
                            
                                    <Season 

                                        name="season"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].seasons }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns width filter */
                            
                                type.name === "width" && 
                            
                                    <Width 

                                        name="width"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].widths }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns height filter */
                            
                                type.name === "height" && 
                            
                                    <Height 

                                        name="height"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].heights }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns diameter filter */
                            
                                type.name === "diameter" && 
                            
                                    <Diameter 

                                        name="diameter"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].diameters }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns radius filter */
                            
                                type.name === "radius" && 
                            
                                    <Radius 

                                        name="radius"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].radiuses }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns offset filter */
                            
                                type.name === "offset" && 
                            
                                    <Offset 

                                        name="offset"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].offsets }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns manufacturers filter */
                            
                                type.name === "manufacturer" && 
                            
                                    <Manufacturer 

                                        name="manufacturer"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].manufacturers }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns model filter */
                            
                                type.name === "model" && 
                            
                                    <Model 

                                        name="model"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].models }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns vehicle type filter */
                            
                                type.name === "vehicleType" && 
                            
                                    <VehicleType 

                                        name="vehicleType"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].vehicle_types }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns vehicle type filter */
                            
                                type.name === "specifications" && 
                            
                                    <Specifications 

                                        name="specifications"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].specifications }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns pitch circle diameter filter */
                            
                                type.name === "pitchCircleDiameter" && 
                            
                                    <PitchCircleDiameter 

                                        name="pitchCircleDiameter"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].pitch_circle_diameters }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns speed rating filter */
                            
                                type.name === "speedRating" && 
                            
                                    <SpeedRating 

                                        name="speedRating"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].speed_ratings }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns noise index filter */
                            
                                type.name === "noiseIndex" && 
                            
                                    <NoiseIndex 

                                        name="noiseIndex"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].noise_indexes }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns load index filter */
                            
                                type.name === "loadIndex" && 
                            
                                    <LoadIndex 

                                        name="loadIndex"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].load_indexes }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns fuel efficiency filter */
                            
                                type.name === "fuelEfficiency" && 
                            
                                    <FuelEfficiency 

                                        name="fuelEfficiency"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].fuel_efficiencies }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns wet breaking efficiency filter */
                            
                                type.name === "wetBreakingEfficiency" && 
                            
                                    <WetBreakingEfficiency 

                                        name="wetBreakingEfficiency"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].wet_grip }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns voltage filter */
                            
                                type.name === "voltage" && 
                            
                                    <Voltage 

                                        name="voltage"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].voltages }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns current filter */
                            
                                type.name === "current" && 
                            
                                    <Current 

                                        name="current"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].currents }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns capacity filter */
                            
                                type.name === "capacity" && 
                            
                                    <Capacity 

                                        name="capacity"
                                    
                                        attributes={ attributes.searchAttributes[ productAttribute ( productType ) ][ 0 ].capacities }

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                            { /** @returns sort order filter */
                            
                                type.name === "sortOrder" && 
                            
                                    <SortOrder 

                                        name="sortOrder"

                                        refetch={ refetch } 
                                        
                                        variables={ variables } /> }

                        </div>

                )
            
            }

        </div>

    );

},

/**
 * 
 * @param { String } type
 * 
 * @returns product type plural
 * 
 */

productAttribute = type => {

    switch ( type ) {

        case "tire"         : return "tires";

        case "rim"          : return "rims";

        case "battery"      : return "batteries";

        default             : return "tires";

    }

}

/** @exports ProductFilters */
 
export default ProductFilters;