import { useState } from "react";


const getDefaultValues = () => {
    return {
        PAGE: 1,
        ENTRIES: 6,
    };
};

const getState = (defaultFilters) => {
    const DEFAULT = getDefaultValues();

    return {
        entries: {
            options: [3, 6, 9],
            selected: DEFAULT.ENTRIES,
        },
        selectedClients: [],
        filters: defaultFilters || [],
        page: DEFAULT.PAGE,
        search: "",
        sort: "",
    };
};

const useTableClients = (defaultFilters = []) => {
    const [state, setState] = useState(() => getState(defaultFilters));

    /* --- */

    const applyFilters = (filters) => {
        setState((prev) => ({
            ...prev,
            filters,
        }));
    };

    const setPage = (page) => {
        setState((prev) => ({
            ...prev,
            page,
        }));
    };

    const setSelectedEntries = (selected) => {
        setState((prev) => ({
            ...prev,
            entries: {
                ...prev.entries,
                selected,
            },
        }));
    };

    const toggleSelectedClients = (clientId) => {
        const selectedClients = state.selectedClients.indexOf(clientId) === -1
            ? [...state.selectedClients].concat(clientId)
            : [...state.selectedClients].filter((id) => id !== clientId);

        setState((prev) => ({
            ...prev,
            selectedClients,
        }));
    };

    const toggleSelectedClientsAll = (ids) => {
        let selectedClients = [];

        if (state.selectedClients.length > 0) {
            if (state.selectedClients.length === ids.length) {
                selectedClients = [];
            } else {
                selectedClients = [...ids];
            }
        } else {
            selectedClients = [...ids];
        }

        setState((prev) => ({
            ...prev,
            selectedClients,
        }));
    };

    /* --- */

    const setSearch = (search) => {
        setState((prev) => ({
            ...prev,
            search,
        }));
    };

    /* --- */

    const setSelectedSort = (sort) => {
        setState((prev) => ({
            ...prev,
            sort,
        }));
    };

    /* --- */

    return {
        state,
        DEFAULT: getDefaultValues(),
        applyFilters,
        setSelectedEntries,
        toggleSelectedClients,
        toggleSelectedClientsAll,
        setPage,
        setSearch,
        setSelectedSort,
    };
};

export default useTableClients;
